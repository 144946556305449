import { List, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { ResponsiveLine } from "@nivo/line";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
const useStyles = makeStyles((theme) => ({
  red: {
    color: "red",
  },
  green: {
    color: "green",
  },
}));
const Chart = ({ users }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:800px)");
  const [data, setData] = useState([]);
  useEffect(() => {
    const formattedUsers = users.map((user) => {
      return {
        id: user.name,
        data: user.history
          .filter((_, index, array) => !matches || array.length - index <= 12)
          .map((time, index, array) => {
            const changeAmount =
              index !== 0
                ? Math.round(
                    (array[index].percent_gain -
                      array[index - 1].percent_gain) *
                      100
                  ) / 100
                : undefined;
            const hasChangedCoins =
              index !== 0 &&
              time.balances &&
              array[index - 1].balances &&
              !_.isEqual(
                array[index - 1].balances.map((c) => c.name).sort(),
                time.balances.map((c) => c.name).sort()
              );

            const hasChangedAmounts =
              changeAmount &&
              index !== 0 &&
              time.balances &&
              array[index - 1].balances &&
              array[index - 1].balances.reduce((a, previousCoin) => {
                const currentCoin = time.balances.find(
                  (b) => b.name === previousCoin.name
                );
                if (currentCoin) {
                  const percentChange = Math.abs(
                    currentCoin.percent - previousCoin.percent
                  );
                  //sloppy guessing todo fix
                  if (percentChange > 10 && changeAmount < 10) return true;
                }
                return a;
              }, false);
            const hasChanged = hasChangedCoins || hasChangedAmounts;
            return {
              x: dayjs(time.timestamp).format("ha D"),
              y: Number(time.percent_gain),
              date: dayjs(time.timestamp).format("ha"),
              changeAmount,
              balances: time.balances.map((b, i) =>
                !hasChanged && index !== 0
                  ? {
                      ...b,
                      color:
                        array[index - 1].balances[i].percent === b.percent
                          ? undefined
                          : array[index - 1].balances[i].percent < b.percent
                          ? "green"
                          : "red",
                    }
                  : b
              ),
              changed: hasChanged,
            };
          }),
      };
    });
    setData(formattedUsers);
  }, [users, matches]);
  return (
    <Paper style={{ height: 550, marginTop: 20, marginBottom: 20 }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        tooltip={({ point }) => {
          if (point.data.balances)
            return (
              <Paper>
                <Typography variant="body2">
                  {point.serieId} - {point.data.date}: {point.data.y}% (
                  {point.data.changeAmount}%)
                </Typography>

                {point.data.balances.map((balance) => (
                  <List
                    dense
                    disablePadding
                    key={`${point.data.x}${point.data.y}${balance.name}`}
                  >
                    <ListItem>
                      <ListItemText
                        className={
                          balance.color ? classes[balance.color] : undefined
                        }
                        primary={`${balance.name} - ${balance.percent}%`}
                      />
                    </ListItem>
                  </List>
                ))}
              </Paper>
            );
          else return null;
        }}
        pointSize={10}
        pointSymbol={(point) => {
          return point.datum.changed ? (
            <g>
              <circle
                fill="#fff"
                r={point.size / 2}
                strokeWidth={point.borderWidth}
                stroke={point.borderColor}
              />
              <circle
                r={point.size / 5}
                strokeWidth={point.borderWidth}
                stroke={point.borderColor}
                fill={point.color}
                fillOpacity={0.35}
              />
            </g>
          ) : (
            <circle
              r={point.size / 5}
              strokeWidth={point.borderWidth}
              stroke={point.borderColor}
              fill={point.color}
              fillOpacity={0.35}
            />
          );
        }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
        }}
        curve="natural"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          legend: "Time (Hour/Date)",
          legendOffset: 66,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "% gain from inital investment",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        colors={{ scheme: "category10" }}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </Paper>
  );
};
export default Chart;
