import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { apiBaseUrl } from "./config";

const DialogPopup = ({ open, handleClose }) => {
  const [data, setData] = useState({
    fetching: true,
    error: false,
    data: [],
  });
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/seasons`);
      if (!response.ok) {
        return setData({ ...data, error: true });
      }
      const text = await response.text();
      const json = JSON.parse(text);
      return setData({
        data: json,
        fetching: false,
        error: false,
      });
    } catch (e) {
      setData({
        ...data,
        fetching: false,
        error: true,
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
      <DialogTitle id="simple-dialog-title">Seasons</DialogTitle>
      <DialogContent>
        <List>
          {data.data
            .sort(
              (a, b) => dayjs(b.start_date).unix() - dayjs(a.start_date).unix()
            )
            .map((season, index, array) => (
              <>
                <ListItem>
                  <ListItemText
                    primary={`Season ${array.length - index}`}
                    secondary={
                      <div>
                        <div>
                          {dayjs(season.start_date).format("ha D MMM YYYY")} -
                          {` `}
                          {dayjs(season.end_date).format("ha D MMM YYYY")}
                        </div>
                        {season.final_standings && (
                          <Typography>
                            Final Standings
                            {!!season.final_standings.length ? (
                              <ol>
                                {season.final_standings
                                  .sort((a, b) => a.rank - b.rank)
                                  .map((standing) => (
                                    <li>{standing.name}</li>
                                  ))}
                              </ol>
                            ) : (
                              " TBD"
                            )}
                          </Typography>
                        )}
                      </div>
                    }
                  />
                </ListItem>
                <Divider />
              </>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogPopup;
