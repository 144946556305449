import React, { useEffect, useState } from "react";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import TropheyIcon from "@mui/icons-material/EmojiEvents";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import "./App.css";
import Chart from "./chart";
import { apiBaseUrl } from "./config";
import Dialog from "./dialogSeason";
function App() {
  const [users, setUsers] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const fetchData = async () => {
    try {
      setIsFetching(true);
      const response = await fetch(`${apiBaseUrl}/api/data`);
      if (!response.ok) {
        return setError(true);
      }
      const text = await response.text();
      const json = JSON.parse(text);
      setUsers(json);
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
      setError(true);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="App">
      <main className="App-header">
        {process.env.NODE_ENV === "production" ? (
          <p>Stakes board</p>
        ) : (
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
        )}
        <p>
          <Button disabled={isFetching} onClick={fetchData} variant="contained">
            {isFetching ? "Refreshing Data" : "Refresh Data"}
          </Button>
        </p>
        {!users ? (
          <>
            {error
              ? "Something fucked up fetching data. Try refreshing in a few."
              : "Fetching data..."}
          </>
        ) : (
          <>
            <Container maxWidth="md">
              {users.map((user) => (
                <Accordion style={{ width: "100%" }} key={user.name}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                      {user.name}
                      {user.percentFromInitial >= 0 ? (
                        <ArrowUpwardIcon style={{ verticalAlign: "top" }} />
                      ) : (
                        <ArrowDownwardIcon style={{ verticalAlign: "top" }} />
                      )}
                      <span
                        style={
                          user.percentFromInitial >= 0
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        {user.percentFromInitial}%
                      </span>
                      {!!user.history.length && (
                        <span>
                          {` `}(
                          {Math.round(
                            (user.percentFromInitial -
                              user.history[user.history.length - 1]
                                .percent_gain) *
                              100
                          ) / 100}
                          %)
                        </span>
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Asset</TableCell>
                            <TableCell>Portfolio %</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {user.balances.map((currency) => (
                            <TableRow key={currency.name}>
                              <TableCell component="th" scope="row">
                                {currency.name}
                              </TableCell>
                              <TableCell>{currency.percent}%</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Container>
            <Container maxWidth="xl">
              {!!users && <Chart users={users} />}
            </Container>
            <Box position="fixed" bottom={10} right={10}>
              <Fab color="primary" onClick={() => setDialogOpen(true)}>
                <TropheyIcon />
              </Fab>
            </Box>
            <Dialog
              open={dialogOpen}
              handleClose={() => setDialogOpen(false)}
            />
          </>
        )}
      </main>
    </div>
  );
}

export default App;
